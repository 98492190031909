/* Register.jsx */
import React from "react";
import loginImg from "../../images/turtle.png";

export class CheckError extends React.Component {
  render() {
    return (
      <div className="App"><div className="login"><div className="container">

      <div className="base-container" ref={this.props.containerRef}>
        <div className="content">
          <div className="image">
            <img src={loginImg} alt="Total Integrated Resources"/> 
          </div>
          <div className="error">
            Oops! Seems I can't connect to the API.
          </div>
        </div>
      </div>

      </div></div></div>
    );
  }
}
