import React from "react";
import axios from 'axios';
import logo128 from "../../images/invisiron_header.png";
import $ from 'jquery';
import "./jquery.jsx";


export class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          name: "the forgotten one",
          tags:{ },
          bodyto: "/dashboard/view",
          status: ""
        };
      }
    
    componentDidMount() {
      var endpoint = "/api/system/sidebar";
      axios.get(endpoint)
      .then(res => {
        const tags = res.data
        this.setState({ tags });
      })
    }

    BodyRecurse(bodylink){
      const bodyto = bodylink
      this.setState({ bodyto })
      $().setActiveMenu(bodylink);
    }

    Logout(){
      var endpoint = "/api/access/authenticate";
      axios.get(endpoint)
      .then(res => {
        if (res.status === 201){
          window.location.reload();
          return false;  
        }

        })
    }

    
    
    render() {      
    
      var fullname = this.state.tags.name;
      var bodyto = this.state.bodyto;      
   
      return (
        <div>                 
          <div className="header-dash" id="headerDash">
            
              <div id="logo">
                <img src={logo128} alt="company-logo" className="header-dash-image" />
                <span className="invisiron-version">
                        INV v1.1-2.OB
                </span>
                <button type="button" className="headerbtn-user" onClick={() => this.BodyRecurse("/user/profile")}>Partner: { fullname }</button>
              </div>
              <nav>
              <label for="drop" class="toggle" id="menu-title">
                <div class="container" id="hamburger-button">
                  <div class="bar1"></div>
                  <div class="bar2"></div>
                  <div class="bar3"></div>
                </div>
              </label>
              <input type="checkbox" id="drop" />
                  <ul class="menu" id="menu-bar">
                      <li>
                          <label for="drop-1" class="toggle">USER MANAGEMENT</label>
                          <a href="#">
                            {this.state.tags.usermanagement && <button type="button" className="menu-btn" id="menu-user">USER MANAGEMENT</button>}
                          </a>
                          <input type="checkbox" id="drop-1"/>
                          <ul>
                            <li>{this.state.tags.modaccount && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/user/add")}>Add User</button>}</li>
                            <li>{this.state.tags.modusertype && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/usertype/add")}>Add UserType</button>}</li>
                            <li>{this.state.tags.viewaccount && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/user/list")}>User List</button>}</li>
                            <li>{this.state.tags.viewusertype && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/usertype/list")}>User Type List</button>}</li>
                            <li>{this.state.tags.viewaccount && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/user/archieve")}>Archived Users</button>}  </li>
                            <li>{this.state.tags.viewusertype && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/usertype/archieve")}>Archived UserTypes</button>}</li>
                            <li>{this.state.tags.viewlog && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/user/logs")}>Logs</button>}</li>
                          </ul> 
                      </li>

                      <li>      
                          <label for="drop-2" class="toggle">DASHBOARD</label>
                          <a href="#">
                            {this.state.tags.dashboard && !this.state.tags.moddashboard && <button type="button" className="menu-btn" id="menu-dashboard" onClick={() => this.BodyRecurse("/dashboard/view")}>DASHBOARD</button>}
                            {this.state.tags.moddashboard && <button type="button" className="menu-btn" id="menu-moddashboard" onClick={() => this.BodyRecurse("/dashboard/view")}>DASHBOARD</button>}
                          </a>
                          <input type="checkbox" id="drop-2"/>
                          <ul>
                            <li>{this.state.tags.moddashboard && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/dashboard/welcometext")}>Welcome Text</button>}</li>
                            <li>{this.state.tags.moddashboard && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/dashboard/newsannouncement")}>News/Announcements</button>}</li>
                            <li>{this.state.tags.moddashboard && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/dashboard/promotions")}>Promotions</button>}</li>
                            <li>{this.state.tags.moddashboard && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/dashboard/view")}>View Dashboard</button>}</li>
                            <li>{this.state.tags.dashboard && !this.state.tags.moddashboard && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/dashboard/view")}>View Dashboard</button>}</li>
                          </ul> 
                      </li>

                      <li>      
                          <label for="drop-3" class="toggle">BRANDING &amp; MARKETING</label>
                          <a href="#">
                            {this.state.tags.onlyviewbranding && <button type="button" className="menu-btn" id="menu-branding">BRANDING &amp; MARKETING</button>}
                            {this.state.tags.modbranding && <button type="button" className="menu-btn" id="menu-modbranding">BRANDING &amp; MARKETING</button>}
                          </a>
                          <input type="checkbox" id="drop-3"/>
                          <ul>
                            <li>{this.state.tags.modbranding && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/branding/assets")}>Brand Assets</button>}</li>
                            <li>{this.state.tags.modbranding && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/branding/guidelines")}>Brand Guidelines</button>}</li>
                            <li>{this.state.tags.modbranding && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/branding/story")}>Brand Story</button>}</li>
                            <li>{this.state.tags.modbranding && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/branding/marketing")}>Marketing Materials</button>}</li>
                            <li>{this.state.tags.modbranding && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/branding/webinar")}>Webinar Templates</button>}</li>
                            <li>{this.state.tags.onlyviewbranding && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/branding/assets/list")}>Brand Assets</button>}</li>
                            <li>{this.state.tags.onlyviewbranding && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/branding/guidelines/list")}>Brand Guidelines</button>}</li>
                            <li>{this.state.tags.onlyviewbranding && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/branding/story/list")}>Brand Story</button>}</li>
                            <li>{this.state.tags.onlyviewbranding && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/branding/marketing/list")}>Marketing Materials</button>}</li>
                            <li>{this.state.tags.onlyviewbranding && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/branding/webinar/list")}>Webinar Templates</button>}</li>
                          </ul> 
                      </li>

                      <li>      
                          <label for="drop-4" class="toggle">TRAINING &amp; MANUALS</label>
                          <a href="#">
                            {this.state.tags.onlyviewtraining && <button type="button" className="menu-btn" id="menu-training">TRAINING &amp; MANUALS</button>}
                            {this.state.tags.modtraining && <button type="button" className="menu-btn" id="menu-modtraining">TRAINING &amp; MANUALS</button>}
                          </a>
                          <input type="checkbox" id="drop-4"/>
                          <ul>
                            <li>{this.state.tags.onlyviewtraining && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/training/manuals/list")}>User Manual</button>}</li>
                            <li>{this.state.tags.onlyviewtraining && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/training/faqs/list")}>FAQs</button>}</li>
                            <li>{this.state.tags.onlyviewtraining && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/training/papers/list")}>White Papers</button>}</li>
                            <li>{this.state.tags.onlyviewtraining && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/training/videos/list")}>Videos</button>}</li>
                            <li>{this.state.tags.modtraining && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/training/manuals")}>User Manual</button>}</li>
                            <li>{this.state.tags.modtraining && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/training/faqs")}>FAQs</button>}</li>
                            <li>{this.state.tags.modtraining && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/training/papers")}>White Papers</button>}</li>
                            <li>{this.state.tags.modtraining && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/training/videos")}>Videos</button>}</li>
                          </ul> 
                      </li>

                      <li>      
                          <label for="drop-5" class="toggle">PARTNER AREA</label>
                          <a href="#">
                            {this.state.tags.modpartner && <button type="button" className="menu-btn" id="menu-modpartner">PARTNER AREA</button>}
                            {this.state.tags.onlyaddpartner && <button type="button" className="menu-btn" id="menu-partner">PARTNER AREA</button>}
                          </a>
                          <input type="checkbox" id="drop-5"/>
                          <ul>
                            <li>{this.state.tags.onlyaddpartner && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/partner/casestudy/add")}>Case Study</button>}</li>
                            <li>{this.state.tags.onlyaddpartner && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/partner/testimonial/add")}>Testimonial</button>}</li>
                            <li>{this.state.tags.modpartner && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/partner/casestudy/list")}>Case Study</button>}</li>
                            <li>{this.state.tags.modpartner && <button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/partner/testimonial/list")}>Testimonial</button>}</li>
                          </ul> 

                      </li>

                      <li>      
                          <label for="drop-6" class="toggle">PRIVACY</label>
                          <a href="#">
                            <button type="button" className="menu-btn" id="menu-privacy" onClick={() => this.BodyRecurse("/dashboard/privacy")}>PRIVACY</button>
                          </a>
                          <input type="checkbox" id="drop-6"/>
                          <ul>
                            <li><button type="button" className="menubtn-list" onClick={() => this.BodyRecurse("/dashboard/privacy")}>Privacy & GDPR Policy</button></li>
                          </ul> 

                      </li>

                      <li id="menu-logout">
                        <a href="#">
                            <button type="button" className="menu-btn" onClick={() => this.Logout()}>LOGOUT</button>
                        </a>
                      </li>

                  </ul>

                  <button type="button" className="menu-btn" id="mobile-logout" onClick={() => this.Logout()}>LOGOUT</button>

              </nav>
            </div>                  

            <div id="mySubdomain" className="subdomain">
                <iframe title="body" src={ bodyto } id="iFrameBody"></iframe>
            </div>     
        </div>
      );      
    }
}
  
