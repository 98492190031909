import React from "react";
import "./App.scss";
import { Login, CheckError } from "./components/login/index";
import { Dashboard } from "./components/dashboard/index";
import axios from 'axios';


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      check: "new"
    };
  }

  componentDidMount() {
    var endpoint = "/api/access/authenticate";
    axios.put(endpoint)
      .then(res => {
        const check = res.data.route;
        this.setState({ check });
      })
      .catch(error => {
        const check = "apifailed";
        this.setState({ check });
      });
  }

  render() {
    return (
      <div>
            {this.state.check === "login" && <Login/>}
            {this.state.check === "dashboard" && <Dashboard/>}
            {this.state.check === "apifailed" && <CheckError/>}
      </div>
    );
  }
}

export default App;
