import React from "react";
import axios from 'axios';
import ippImg from "../../images/ipp.png";
import { EmailError, SentEmail } from "./index";


export class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      check: "good",
      buttonDisabled: 1,
    };
  }

  postuserdata(email){
    const check = "good";
    const buttonDisabled = 0;
    const currentURL = window.location.href;
    this.setState({ check, buttonDisabled });
    var endpoint = "/api/access/forgotpassword";
    const forgot = {
      email: email,
      currentURL: currentURL,
    }  
    axios.post(endpoint, forgot)
    .then(res => {
      const check = "sent";
      const buttonDisabled = 1;
      this.setState({ check , buttonDisabled });
    })
    .catch(error => {
      const check = "bad";
      const buttonDisabled = 1;
      this.setState({ check, buttonDisabled });
    })
  }

  handleEmailChange = e => this.setState({email: e.target.value}); 

  render() {
    return (
          <div className = "column"> 
            <span className="inputTitle">Enter your registered email:</span>
            <hr className="horLine" />
              <div className="content">              
                <div className="form">
                  <div className="form-group"><input type="text" name="email" placeholder="Email" onChange={this.handleEmailChange}/></div>
                </div>
              </div>
              <div className="footer">
                <button type="button" className="btn" onClick={() => this.postuserdata(this.state.email)} disabled = {this.state.buttonDisabled === 0 ? 'disabled' : ''}>                    
                  SUBMIT
                </button>
              </div>
              
            <hr className="horLine" />
            {this.state.check === "bad" && <EmailError/>}
            {this.state.check === "sent" && <SentEmail/>}            
            {this.state.check === "good" && <div className="errorSpacer"></div>}
          </div>
          
          
          
    );
  }
}
