import React from "react";

export class EmailError extends React.Component {
  render() {
    return(

      <div className="loginerror">
        Forgot password failed: The email is incorrect or not registered.
      </div>

    );
  }
}

export class SentEmail extends React.Component {
  render() {
    return(

      <div className="sent">
        Your new password has been sent to your email.
      </div>

    );
  }
}
  