import $ from 'jquery';

// Mobile menu closes when clicking outside menu
window.addEventListener('mousedown', function(e){
  if (e.which === 1){
    const menuBar = document.getElementById('menu-bar');
    const menuTitle = document.getElementById('menu-title');
    if ( menuBar && menuBar.contains(e.target)){
      if(!$("#drop").is(':checked')){
        $('#hamburger-button').toggleClass("change");
      }
    }    
    else if (menuTitle && menuTitle.contains(e.target)){
        $('#hamburger-button').toggleClass("change");
    }
    else {
      if($("#drop").is(':checked')){
        $('#hamburger-button').toggleClass("change");
        $("#drop").prop('checked', false);
      }
    }
  }
});

// Mobile Menu closes when iFrame is loaded
document.addEventListener(
  'load',
  function(event){
      var elm = event.target;
      if( elm.id === 'iFrameBody'){ // or any other filtering condition
          if($("#drop").is(':checked')){
            $('#hamburger-button').toggleClass("change");
            $("#drop").prop('checked', false);
          }
      }
      if (document.getElementById("iFrameBody") !== null){
        document.getElementById("iFrameBody").contentWindow.document.onclick = 
        function() {
          if($("#drop").is(':checked')){
            $('#hamburger-button').toggleClass("change");
            $("#drop").prop('checked', false);
          }
        };
      }

      $("#drop-1").prop('checked', false);
      $("#drop-2").prop('checked', false);
      $("#drop-3").prop('checked', false);
      $("#drop-4").prop('checked', false);
      $("#drop-5").prop('checked', false);
      $("#drop-6").prop('checked', false);
  },
  true // Capture event
);

//Sets the active menu
$.fn.setActiveMenu = function (bodylink) {

  if(bodylink == null){
    $("#menu-user").css("color", "#dda63a");
    $("#menu-dashboard").css("color", "white");
    $("#menu-moddashboard").css("color", "white");
    $("#menu-branding").css("color", "#dda63a");
    $("#menu-modbranding").css("color", "#dda63a");
    $("#menu-training").css("color", "#dda63a");
    $("#menu-modtraining").css("color", "#dda63a");
    $("#menu-partner").css("color", "#dda63a");
    $("#menu-modpartner").css("color", "#dda63a");
    $("#menu-privacy").css("color", "#dda63a");
  }

  if(bodylink.includes("user")){
    $("#menu-user").css("color", "white");
    $("#menu-dashboard").css("color", "#dda63a");
    $("#menu-moddashboard").css("color", "#dda63a");
    $("#menu-branding").css("color", "#dda63a");
    $("#menu-modbranding").css("color", "#dda63a");
    $("#menu-training").css("color", "#dda63a");
    $("#menu-modtraining").css("color", "#dda63a");
    $("#menu-partner").css("color", "#dda63a");
    $("#menu-modpartner").css("color", "#dda63a");
    $("#menu-privacy").css("color", "#dda63a");
  }

  if(bodylink.includes("dashboard")){
    $("#menu-user").css("color", "#dda63a");
    $("#menu-dashboard").css("color", "white");
    $("#menu-moddashboard").css("color", "white");
    $("#menu-branding").css("color", "#dda63a");
    $("#menu-modbranding").css("color", "#dda63a");
    $("#menu-training").css("color", "#dda63a");
    $("#menu-modtraining").css("color", "#dda63a");
    $("#menu-partner").css("color", "#dda63a");
    $("#menu-modpartner").css("color", "#dda63a");
    $("#menu-privacy").css("color", "#dda63a");
  }

  if(bodylink.includes("privacy")){
    $("#menu-user").css("color", "#dda63a");
    $("#menu-dashboard").css("color", "#dda63a");
    $("#menu-moddashboard").css("color", "#dda63a");
    $("#menu-branding").css("color", "#dda63a");
    $("#menu-modbranding").css("color", "#dda63a");
    $("#menu-training").css("color", "#dda63a");
    $("#menu-modtraining").css("color", "#dda63a");
    $("#menu-partner").css("color", "#dda63a");
    $("#menu-modpartner").css("color", "#dda63a");
    $("#menu-privacy").css("color", "white");
  }

  if(bodylink.includes("branding")){
    $("#menu-user").css("color", "#dda63a");
    $("#menu-dashboard").css("color", "#dda63a");
    $("#menu-moddashboard").css("color", "#dda63a");
    $("#menu-branding").css("color", "white");
    $("#menu-modbranding").css("color", "white");
    $("#menu-training").css("color", "#dda63a");
    $("#menu-modtraining").css("color", "#dda63a");
    $("#menu-partner").css("color", "#dda63a");
    $("#menu-modpartner").css("color", "#dda63a");
    $("#menu-privacy").css("color", "#dda63a");
  }

  if(bodylink.includes("training")){
    $("#menu-user").css("color", "#dda63a");
    $("#menu-dashboard").css("color", "#dda63a");
    $("#menu-moddashboard").css("color", "#dda63a");
    $("#menu-branding").css("color", "#dda63a");
    $("#menu-modbranding").css("color", "#dda63a");
    $("#menu-training").css("color", "white");
    $("#menu-modtraining").css("color", "white");
    $("#menu-partner").css("color", "#dda63a");
    $("#menu-modpartner").css("color", "#dda63a");
    $("#menu-privacy").css("color", "#dda63a");
  }

  if(bodylink.includes("partner")){
    $("#menu-user").css("color", "#dda63a");
    $("#menu-dashboard").css("color", "#dda63a");
    $("#menu-moddashboard").css("color", "#dda63a");
    $("#menu-branding").css("color", "#dda63a");
    $("#menu-modbranding").css("color", "#dda63a");
    $("#menu-training").css("color", "#dda63a");
    $("#menu-modtraining").css("color", "#dda63a");
    $("#menu-partner").css("color", "white");
    $("#menu-modpartner").css("color", "white");
    $("#menu-privacy").css("color", "#dda63a");
  }
};
