import React from "react";
import ippImg from "../../images/ipp.png";
import { ForgotPassword } from "./index";
import axios from 'axios';

export class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      check: "good"
    };
  }
  
  componentDidMount = () => {
    document.addEventListener("keydown", (e) => 
      e.code === "Enter" && this.loginForm(this.state.username,this.state.password,this.state.otp))
  }

  loginForm(username,password,otp){
    const check = "good";
    this.setState({ check });
    var endpoint = "/api/access/authenticate";
    const login = {
      username: username,
      password: password,
      otp: otp
    }
    axios.post(endpoint, login)
    .then(res => {
      window.location.reload();
      return false;
    })
    .catch(error => {
      const check = "bad";
      const errorMessage = error.response.data.message
      this.setState({ check, errorMessage });
    })
  }

  loginMenu(){   
    document.getElementById("login-form").style.display = "block"; 
    const check = "good";
    this.setState({ check });
    return false;
  }

  forgotPassword(){   
    document.getElementById("login-form").style.display = "none"; 
    const check = "forgot";
    this.setState({ check });
    return false;
  }

  loginError(){ 
      return(
  
        <div className="loginerror">
          {this.state.errorMessage}
        </div>
  
      );
  }

  handleUserNameChange = e => this.setState({username: e.target.value}); 
  handlePasswordChange = e => this.setState({password: e.target.value}); 
  handleOTPChange = e => this.setState({otp: e.target.value}); 

  render() {
    return (
      <div className="App">
        <div className="base-logo">
        <button className="logoButton" onClick={() => this.loginMenu()}><img src={ippImg} alt="Invisiron Partner Page" width="300px"/></button>
        <p/>
        <div className="base-container" ref={this.props.containerRef}>
          <div className = "column" id = "login-form"> 
            <span className="inputTitle">Authorized Users:</span>
            <hr className="horLine" />
              <div className="content">              
                <div className="form">
                  <div className="form-group"><input type="text" name="username" placeholder="Username" onChange={this.handleUserNameChange}/></div>
                  <div className="form-group"><input type="password" name="password" placeholder="Password" onChange={this.handlePasswordChange}/></div>
                  <div className="form-group"><input type="text" name="otp" placeholder="One-Time Password" onChange={this.handleOTPChange}/></div>
                </div>
              </div>
              {this.state.check === "good" && <div className="errorSpacer"></div>}
              {this.state.check === "bad" && this.loginError()}
              
              <div className="footer">
                <button type="button" className="btn" onClick={() => this.loginForm(this.state.username,this.state.password,this.state.otp)}>
                  LOGIN
                </button>
              </div>
            <hr className="horLine" />
            <button type="button" className="forgot" onClick={() => this.forgotPassword()}>
                  Forgot Password
            </button>
          </div>
          {this.state.check === "forgot" && <ForgotPassword/>}
        </div>
      </div>
      </div>
    );
  }
}
